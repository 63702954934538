import store from '@/store';

let socket;

const initWebSocket = (authorizationToken, requestId, taskIds) => {
    if (socket) {
        console.warn('WebSocket 已经连接，无需重复初始化。');
        return;
    }

    socket = new WebSocket('ws://114.55.60.77:9800/api/v1/recreate/wss');

    socket.onopen = () => {
        const message = {
            authorization: "Bearer " + authorizationToken,
            request_id: requestId,
            task_ids: taskIds,
        };
        socket.send(JSON.stringify(message));
    };

    socket.onmessage = (event) => {
        const data = JSON.parse(event.data);
        // 更新视频状态
        if (data.task_id && data.status) {
            if (data.status === '已完成' && data.recreated_video_url && data.recreated_thumbnail_url) {
                updateVideoCompletion(data.task_id, data.recreated_video_url, data.recreated_thumbnail_url);
            } else {
                updateTaskStatus(data.task_id, data.status);
            }
        }
    };

    socket.onerror = (error) => {
        console.error('WebSocket 错误:', error);
    };

    socket.onclose = () => {
        console.log('WebSocket 连接关闭');
        socket = null;

        setTimeout(() => {
            console.log('尝试重连WebSocket...');
            initWebSocket(authorizationToken, requestId, taskIds);
        }, 5000);  // 5秒后重连


    };

};

const sendWebSocketMsg = (taskIds) => {
    const message = { task_ids: taskIds};
    socket.send(JSON.stringify(message));
};

const closeWebSocket = () => {
    if (socket) {
        socket.close();
        socket = null;
    }
};

const updateTaskStatus = (taskId, status) => {
    store.commit('RecreateVideo/UPDATE_VIDEO_STATUS', { taskId, status });
};

const updateVideoCompletion = (taskId, recreatedVideoUrl, recreatedThumbnailUrl) => {
  store.commit('RecreateVideo/UPDATE_VIDEO_COMPLETION', {
    taskId,
    recreatedVideoUrl,
    recreatedThumbnailUrl,
  });
};

export const websocketService = {
    initWebSocket,
    closeWebSocket,
    sendWebSocketMsg,
};

