<template>
  <div class="profile">
    <h2>欢迎您, {{ user.nickname }}</h2>
    <button @click="handleLogout">退出登录</button>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'UserProfile',
  computed: {
    ...mapState(['user']),
  },
  methods: {
    ...mapActions(['logout']),
    handleLogout() {
      this.logout();
      this.$router.push({ name: 'HomePage' });
    },
  },
};
</script>

<style scoped>
.profile {
  text-align: center;
  margin-top: 50px;
}

.profile h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.profile button {
  padding: 10px 20px;
  background-color: #e53e3e;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.profile button:hover {
  background-color: #c53030;
}
</style>
