<template>
  <div>
    <div v-if="user" class="user-info">
      <p class="nickname">欢迎您 {{ user.nickname }}</p>
      <button class="logout-button" @click="logout">退出登录</button>
    </div>
    <button v-else class="login-button" @click="$emit('show-login')">登录</button>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'UserInfo',

  computed: {
    ...mapState(['user']),
  },

  methods: {
    ...mapActions(['logout']),
  },
};
</script>

<style scoped>
.nickname {
  color: #fff;
  font-size: 18px;
  margin-bottom: 15px;
}

.login-button, .logout-button {
  width: 100%;
  padding: 12px;
  background-color: #e53e3e;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease, transform 0.2s;
}

.logout-button {
  background-color: #4a5568;
}

.login-button:hover, .logout-button:hover {
  background-color: #c53030;
  transform: translateY(-3px);
}
</style>
