<template>
  <div class="video-feature-area">
    <h2 class="video-title">视频创作</h2>
    <div class="video-content">
      <!-- 视频预览 -->
      <div class="video-preview-container">
        <video 
          v-if="selectedVideo" 
          ref="videoElement" 
          :src="selectedVideo" 
          controls 
          class="video-preview"
        > </video>
        <div v-else class="video-placeholder">上传视频后点击视频将在此预览</div>
      </div>

      <!-- 上传视频 -->
      <div class="video-list">
        <div class="upload-button-container">
          <div 
            class="video-item upload-button" 
            @click="triggerFileUpload"
          >
            <div 
              class="thumbnail upload-thumbnail" 
              :class="{ 'uploading': isUploading }" 
              @click="!isUploading && triggerFileUpload"
            > 
              {{ isUploading ? '视频上传中...' : '点击上传视频' }} 
            </div>
            <input type="file" class="file-input" @change="handleVideoUpload" style="display: none;" />
          </div>
        </div>

        <!-- 视频列表 -->
        <div class="video-list-container">
          <div v-for="video in videos" :key="video.recreate_id" class="video-item">
            <div class="thumbnail-container">
              <img 
                :src="video.video_thumbnail_url" 
                alt="Video thumbnail" 
                class="thumbnail" 
                :class="{ 'disabled-thumbnail': isUploading }"
                @click="!isUploading && selectVideo(video.video_url)"
              />
              <div class="separator">➜</div>

              <div v-if="video.status === '已完成'">
                <img 
                  :src="video.recreated_thumbnail_url" 
                  alt="Processed thumbnail" 
                  class="thumbnail" 
                  :class="{ 'disabled-thumbnail': isUploading }"
                  @click="!isUploading && selectVideo(video.recreated_video_url)"
                />
              </div>

              <div v-else>
                <button 
                  class="status-button" 
                  :class="getStatusClass(video.status)" 
                  :disabled="video.status !== '空闲' || isUploading"
                  @click="startCreation(video.recreate_id)"
                >
                  {{ getStatusText(video.status) }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import { mapGetters, mapState, mapActions } from 'vuex';
import { websocketService } from '@/services/websocket';

export default {
  name: 'RecreateVideo',

  data() {
    return {
      selectedVideo: null,
      taskIds: [],
    };
  },

  computed: {
    ...mapState('RecreateVideo', ['videos']),
    ...mapState(['user']),
    ...mapGetters(['showLoginModal']),
  },

  created() {
    if (this.user) {
      this.fetchVideoList().then(() => {
        this.initializeWebSocket();
      });
    }
  },

  watch: {
    user(newUser) {
      if (newUser) {
        this.fetchVideoList().then(() => {
          this.initializeWebSocket();
        });
      } else {
        websocketService.closeWebSocket(); 
      }
    },
    videos: {
      handler() {
        if (this.user) {
          this.initializeWebSocket(); 
        }
      },
      deep: true,
    },
  },

  mounted() {
    window.addEventListener('storage', this.checkUser); 
  },

  beforeUnmount() {
    window.removeEventListener('storage', this.checkUser);
    websocketService.closeWebSocket();
  },

  methods: {
    ...mapActions('RecreateVideo', ['fetchVideoList', 'uploadVideo']), // Vuex actions

    initializeWebSocket() {
      if (!this.user || this.videos.length === 0) return;

      const taskIds = this.videos.map(video => video.recreate_id);
      const authorizationToken = this.user.accessToken;
      const requestId = uuidv4();

      websocketService.initWebSocket(authorizationToken, requestId, taskIds);
    },


    closeLoginModal() {
      this.$store.commit('SET_SHOW_LOGIN_MODAL', false);
    },

    checkUser(event) {
      if (event.key === 'user') {
        this.fetchVideoList();
      }
    },

    async handleVideoUpload(event) {
      const file = event.target.files[0];
      console.log('选择的文件:', file);
      if (file) {
        this.selectedVideo = URL.createObjectURL(file);

        this.isUploading = true;

        try {
          const response = await this.uploadVideo(file);
          if (response && response.key) {
            console.log('上传和轮询完成');
            const taskIds = this.videos.map(video => video.recreate_id);
            websocketService.sendWebSocketMsg(taskIds);
          } else {
            console.error('上传失败');
          }
        } catch (error) {
          console.error('上传或轮询时发生错误:', error);
        } finally {
          console.log("上传完成, 开始重置 isUploading: ", this.isUploading);
          this.isUploading = false;
          event.target.value = '';
          this.$forceUpdate();
          console.log("上传完成, 完成重置 isUploading: ", this.isUploading);
        }

      } else {
        console.error('未选择文件');
      }
    },

    selectVideo(videoUrl) {
      this.selectedVideo = videoUrl;
    },

    triggerFileUpload() {
      this.$el.querySelector('.file-input').click();
    },

    async startCreation(taskId) {
      try {
        const response = await this.$axios.post('/recreate/submit', {
          task_id: taskId,
        });
        console.log('请求成功:', response.data);
      } catch (error) {
        console.error('请求失败:', error);
      }
    },

    getStatusText(status) {
      const statusTexts = {
        '空闲': '开始创作',
        '排队中': '排队中',
        '处理中': '创作中',
        '已完成': '已完成',
      };
      return statusTexts[status];
    },

    getStatusClass(status) {
      const statusClasses = {
        '空闲': 'status-submit',
        '排队中': 'status-queue',
        '处理中': 'status-processing',
        '已完成': 'status-completed',
      };
      return statusClasses[status]; 
    },
  },
};
</script>

<style scoped>
.video-feature-area {
  display: flex;
  flex-direction: column;
  background-color: #f0f4ff;
  padding: 40px;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.video-title {
  margin-bottom: 20px;
  font-size: 28px;
  font-family: 'Poppins', sans-serif;
  color: #333;
  text-align: center;
}

.video-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.video-preview-container {
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #eaeaea;
  border-radius: 12px;
  margin-right: 20px;
  height: 400px;
  overflow: hidden;
}

.video-preview {
  width: 100%;
  max-height: 100%;
  border-radius: 12px;
}

.video-placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: #666;
  font-size: 20px;
}

.video-list {
  flex: 1;
  margin-left: 20px;
}

.upload-button-container {
  position: sticky;
  top: 20px;
  z-index: 100;
  background-color: #f0f4ff; 
}

.video-list-container {
  max-height: 400px;
  overflow-y: auto;
}

.video-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.upload-button {
  width: calc(240px + 20px);
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.thumbnail-container {
  display: flex;
  align-items: center;
}

.thumbnail {
  width: 120px;
  height: 80px;
  border-radius: 12px;
  margin-right: 10px;
}

.separator {
  margin: 0 10px;
  font-size: 24px;
}

.status-button {
  width: 120px;
  height: 80px;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
}

.status-submit {
  background-color: #28a745; /* Green for submit */
}

.status-queue {
  background-color: #ffc107; /* Yellow for queue */
}

.status-processing {
  background-color: #17a2b8; /* Blue for processing */
}

.status-completed {
  background-color: #007bff; /* Default color for completed */
}

.status-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.upload-thumbnail {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border: 2px dashed #007bff;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  color: #007bff;
  font-weight: bold;
  transition: background-color 0.3s;
}


.thumbnail.disabled-thumbnail {
  opacity: 0.5;
  cursor: not-allowed;
}

.upload-thumbnail.uploading {
  background-color: #ccc;
  cursor: not-allowed;
  color: #fff;
}





@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.status-processing {
  background-color: #17a2b8;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: not-allowed;
  position: relative;
  padding-right: 30px; /* 给旋转圈留出空间 */
}

.status-processing::after {
  content: '';
  position: absolute;
  top: 50%;
  right: 10px;
  width: 12px;
  height: 12px;
  margin-top: -6px;
  border: 2px solid rgba(255, 255, 255, 0.6);
  border-top: 2px solid white;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}


</style>
