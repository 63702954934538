import axios from '@/services/axios';

const state = {
  videos: [],
};

const mutations = {
  SET_VIDEOS(state, videos) {
    state.videos = videos;
  },

  CLEAR_VIDEOS(state) {
    // 清空视频列表
    state.videos = []; 
  },

  UPDATE_VIDEO_STATUS(state, { taskId, status }) {
        const video = state.videos.find(video => video.recreate_id === taskId);
        if (video) {
            video.status = status; // 更新视频状态
        }
    },

  UPDATE_VIDEO_COMPLETION(state, { taskId, recreatedVideoUrl, recreatedThumbnailUrl }) {
    const video = state.videos.find(video => video.recreate_id === taskId);
    if (video) {
      video.status = '已完成'; // 更新为已完成状态
      video.recreated_video_url = recreatedVideoUrl; // 更新处理后的视频 URL
      video.recreated_thumbnail_url = recreatedThumbnailUrl; // 更新处理后的视频缩略图
    }
  },

};

const actions = {

  async fetchVideoList({ commit }) {
    const user = JSON.parse(localStorage.getItem('user'));
    const accessToken = user ? user.accessToken : null;
    if (!accessToken) return;

    try {
      const response = await axios.get('/recreate/history?page=1&page_size=100');
      if (response.data.code === 200) {

        console.log("获取视频列表成功: ", response.data.data.items)

        commit('SET_VIDEOS', response.data.data.items);
      } else {
        console.error(response.data.message);
      }
    } catch (error) {
      console.error('获取视频列表失败:', error);
    }
  },

  async pollUploadResult({ dispatch }, key) {
    const pollInterval = 2000; // 轮询间隔时间 (2秒)

    return new Promise((resolve, reject) => {
      const polling = setInterval(async () => {
        try {
          const resultResponse = await axios.get('/upload/result', {
            params: { key },
          });

          if (resultResponse.status === 200) {
            console.log('上传完成');
            clearInterval(polling); // 停止轮询
            await dispatch('fetchVideoList');
            resolve({ key }); 
          } else if (resultResponse.data.code === 40012) {
            console.log('上传进行中...');
          } else {
            console.error('未知的上传状态', resultResponse.data);
            clearInterval(polling); 
            reject('上传失败');
          }
        } catch (error) {
          console.error('轮询错误:', error);
          clearInterval(polling);
          reject('轮询时出错');
        }
      }, pollInterval);
    });
  },

  async uploadVideo({ dispatch  }, file) {
    const fileName = file.name;
    try {
        const response = await axios.post('/upload/video', { fname: fileName });

      if (response.data.code === 200) {
        const { key, upload_token } = response.data.data;

        // 使用 FormData 上传文件
        const formData = new FormData();
        formData.append('key', key);
        formData.append('token', upload_token);
        formData.append('file', file);

        // 上传文件
        const uploadResponse = await axios.post('https://up-z1.qiniup.com', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        if (uploadResponse.status === 200) {
          console.log('视频上传成功，开始轮询上传结果...');
          return await dispatch('pollUploadResult', key);
        } else {
          console.error('视频上传失败', uploadResponse);
          return null;
        }
      } else {
        console.error(response.data.message);
        return null;
      }
    } catch (error) {
      console.error('获取上传 token 失败:', error);
      return null;
    }
  },


};

const getters = {
  videos: (state) => state.videos,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
