import axios from 'axios';
import store from '@/store';
import { v4 as uuidv4 } from 'uuid';

// 设置 Axios 的基础 URL
axios.defaults.baseURL = 'http://114.55.60.77:9800/api/v1';


// 请求拦截器，在请求头中添加 accessToken
axios.interceptors.request.use(
  config => {
    const user = JSON.parse(localStorage.getItem('user'));
    const accessToken = user ? user.accessToken : null;
    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`;
      config.headers['requestId'] = uuidv4();
    }
    return config;
  },
  error => Promise.reject(error)
);


// 响应拦截器，处理401错误
axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {

      console.log('401错误，显示登录弹窗');

      // 触发 Vuex mutation，显示登录窗口
      store.commit('SET_SHOW_LOGIN_MODAL', true);
    }
    return Promise.reject(error);
  }
);

export default axios;
