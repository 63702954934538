<template>
  <div class="login-modal" @click.self="closePhoneLogin">
    <div class="login-container">
      <h3>手机号登录</h3>
      <input v-model="phoneNumber" type="text" placeholder="请输入手机号" class="input-field" maxlength="11" @input="validatePhoneNumberInput"/>
      <div class="code-container">
        <input v-model="verificationCode" type="text" placeholder="请输入验证码" class="input-field" maxlength="6" @input="validateNumericInput"/>
        <button @click="sendVerificationCode" :disabled="isSendingCode || countdown > 0" class="code-button">
          <span v-if="countdown === 0">{{ isSendingCode ? "发送中..." : "获取验证码" }}</span>
          <span v-else>已发送({{ countdown }}s)</span>
        </button>
      </div>
      <button @click="submitLogin" :disabled="isLoggingIn" class="login-submit-button">登录</button>
      <p v-if="loginError" class="error-message">{{ loginError }}</p>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'PhoneLoginModal',

  data() {
    return {
      phoneNumber: '',
      verificationCode: '',
      isSendingCode: false,
      isLoggingIn: false,
      loginError: '',
      countdown: 0,
      countdownTimer: null,
    };
  },

  methods: {
    ...mapActions(['login']),

    validatePhoneNumberInput(event) {
      const input = event.target.value;
      event.target.value = input.replace(/\D/g, '');
      this.phoneNumber = event.target.value;
    },

    validateNumericInput(event) {
      const input = event.target.value;
      event.target.value = input.replace(/\D/g, '');
      this.verificationCode = event.target.value;
    },

    async sendVerificationCode() {
      if (!this.phoneNumber) {
        this.loginError = '请输入手机号';
        return;
      }

      this.isSendingCode = true;
      this.loginError = '';

      try {
        
        const response = await this.$axios.post('/auth/optcode', {
          phone: this.phoneNumber,
          code_type: 'login',
        });

        if (response.data.code === 200) {
          this.startCountdown();
        } else {
          this.loginError = response.data.message || '发送验证码失败';
        }
      } catch (error) {
        this.loginError = '验证码发送失败，请稍后重试';
      } finally {
        this.isSendingCode = false;
      }
    },

    startCountdown() {
      this.countdown = 60;
      this.countdownTimer = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--;
        } else {
          this.resetCountdown();
        }
      }, 1000);
    },

    resetCountdown() {
      clearInterval(this.countdownTimer);
      this.countdown = 0;
      this.countdownTimer = null;
    },

    async submitLogin() {
      if (!this.phoneNumber || !this.verificationCode) {
        this.loginError = '请输入手机号和验证码';
        return;
      }

      this.isLoggingIn = true;
      this.loginError = '';

      try {
        await this.login({
          phone: this.phoneNumber,
          code: this.verificationCode,
        });
        this.closePhoneLogin();
      } catch (error) {
        this.loginError = error.message || '登录失败，请稍后重试';
      } finally {
        this.isLoggingIn = false;
      }
    },

    closePhoneLogin() {
      this.$emit('close');
      this.phoneNumber = '';
      this.verificationCode = '';
      this.loginError = '';
      this.resetCountdown();
    },
  },

  beforeUnmount() {
    if (this.countdownTimer) {
      clearInterval(this.countdownTimer);
    }
  },
};
</script>

<style scoped>
/* 登录弹窗的样式 */
.login-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
}

.login-container {
  width: 100%;
  max-width: 400px;
  background: linear-gradient(135deg, #1a202c, #2d3748);
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: fadeIn 0.3s ease-in-out;
  border: 1px solid #4a5568;
}

.login-container h3 {
  font-size: 24px;
  color: #ffffff;
  margin-bottom: 20px;
  font-family: 'Roboto', sans-serif;
  text-align: center;
}

.input-field {
  width: calc(100% - 20px);
  padding: 12px;
  border: 1px solid #4a5568;
  border-radius: 8px;
  font-size: 16px;
  background: #1a202c;
  color: #ffffff;
  margin-bottom: 15px;
  transition: border-color 0.3s;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.input-field:focus {
  border-color: #38b2ac;
  outline: none;
}

.code-container {
  display: flex;
  width: 100%;
  gap: 10px;
  margin-bottom: 20px;
  justify-content: space-between;
}

.code-button {
  flex-shrink: 0;
  padding: 10px;
  background-color: #38b2ac;
  color: #ffffff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
  height: 40px;
  width: 150px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.code-button:disabled {
  background-color: rgba(56, 178, 172, 0.5);
  cursor: not-allowed;
}

.login-submit-button {
  width: 100%;
  padding: 12px;
  background-color: #e53e3e;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease, transform 0.2s;
}

.login-submit-button:disabled {
  background-color: rgba(229, 62, 62, 0.5);
  cursor: not-allowed;
}


.error-message {
  margin-top: 20px;
  color: #e53e3e;
  font-size: 14px;
  text-align: center;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@media (max-width: 500px) {
  .login-container {
    padding: 20px;
    max-width: 100%;
  }

  .code-container {
    flex-direction: column;
  }

  .code-button {
    width: 100%;
    margin-top: 10px;
  }
}
</style>
