import { createStore } from 'vuex';
import axios from '@/services/axios';
import RecreateVideo from './modules/RecreateVideo';

export default createStore({

  state: {
    user: JSON.parse(localStorage.getItem('user')) || null,
    showLoginModal: false,
  },

  mutations: {
    SET_USER(state, user) {
      state.user = user;
      localStorage.setItem('user', JSON.stringify(user));
    },

    LOGOUT(state) {
      state.user = null;
      localStorage.removeItem('user');
    },

    SET_SHOW_LOGIN_MODAL(state, value) { 
      state.showLoginModal = value;
    },
  },

  actions: {

    async login({ commit, dispatch }, { phone, code }) {
      const response = await axios.post('/auth/login', { phone, code });
      if (response.data.code === 200) {
        const nickname = response.data.data.nickname;
        const accessToken = response.data.data.access_token;
        
        const user = {
          nickname: nickname,
          accessToken: accessToken,
          isLogin: true
        };
        commit('SET_USER', user);
        commit('SET_SHOW_LOGIN_MODAL', false);
        // 登录后获取视频列表
        await dispatch('RecreateVideo/fetchVideoList', null, { root: true });
      } else {
        commit('SET_SHOW_LOGIN_MODAL', true);
        throw new Error(response.data.message || '登录失败');
      }
    },

    logout({ commit }) {
      commit('LOGOUT');
      commit('SET_SHOW_LOGIN_MODAL', false);
      // 退出登录后清空视频列表
      commit('RecreateVideo/CLEAR_VIDEOS', null, { root: true });
    },
  },

  getters: {
    isLoggedIn: state => !!state.user,
    showLoginModal: state => state.showLoginModal,
  },

  modules: {
    RecreateVideo,
  },

});
