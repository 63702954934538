import { createRouter, createWebHistory } from 'vue-router';

import store from '../store';
import HomePage from '../views/Home.vue';
import UserProfile from '../views/Profile.vue';

const routes = [
  { path: '/', name: 'HomePage', component: HomePage },
  { path: '/profile', name: 'UserProfile', component: UserProfile, meta: { requiresAuth: true } },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// 添加导航守卫
router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth && !store.state.user) {
    next({ name: 'LoginPage' });
  } else {
    next();
  }
});

export default router;
