import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import axiosPlugin from './plugins/axios';

const app = createApp(App);

// 恢复用户状态
const user = JSON.parse(localStorage.getItem('user'));
if (user) {
  store.commit('SET_USER', user);
}

app.use(router);
app.use(store);
app.use(axiosPlugin);

app.mount('#app');
