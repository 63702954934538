<template>
  <div class="home-container">
    <!-- 左侧功能选择区域 -->
    <div class="left-sidebar">

      <ul>
        <li @click="selectFeature('recreate')" :class="{ active: selectedFeature === 'recreate' }">视频创作</li>
      </ul>
      
      <!-- 用户信息和登录/退出按钮 -->
      <UserInfo @show-login="showPhoneLogin" />
    </div>

    <!-- 中间功能显示区域 -->
    <div class="content-area">
      <!-- 视频创作功能 -->
      <RecreateVideo v-if="selectedFeature === 'recreate'" />
    </div>

    <!-- 手机号登录弹窗 -->
    <PhoneLoginModal v-if="showLoginModal" @close="closePhoneLogin" />
  </div>
</template>

<script>
import RecreateVideo from '@/components/RecreateVideo.vue';
import PhoneLoginModal from '@/components/PhoneLoginModal.vue';
import UserInfo from '@/components/UserInfo.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'HomePage',

  components: {
    RecreateVideo,
    PhoneLoginModal,
    UserInfo,
  },

  data() {
    return {
      selectedFeature: 'recreate',
      showPhoneLoginModal: false,
    };
  },

  computed: {
    ...mapGetters(['showLoginModal']),
  },

  methods: {
    showPhoneLogin() {
      // this.showPhoneLoginModal = true;
      this.$store.commit('SET_SHOW_LOGIN_MODAL', true);
    },

    closePhoneLogin() {
      this.$store.commit('SET_SHOW_LOGIN_MODAL', false);
      // this.showPhoneLoginModal = false;
    },
    
    selectFeature(feature) {
      this.selectedFeature = feature;
    },
  },
};
</script>

<style scoped>
.home-container {
  display: flex;
  height: 100vh;
  background-color: #f9f9f9;
  font-family: 'Roboto', sans-serif;
}

.left-sidebar {
  width: 20%;
  background-color: #2d3748;
  color: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 12px;
}

.left-sidebar ul {
  list-style: none;
  padding: 0;
}

.left-sidebar ul li {
  margin-bottom: 20px;
  cursor: pointer;
  padding: 15px;
  background-color: #4a5568;
  border-radius: 8px;
  text-align: center;
  transition: background-color 0.3s ease, transform 0.2s;
}

.left-sidebar ul li:hover,
.left-sidebar ul li.active {
  background-color: #38b2ac;
  transform: translateY(-3px);
}

.content-area {
  flex: 1;
  padding: 40px;
  overflow-y: auto;
  background-color: #edf2f7;
  border-radius: 12px;
}
</style>
